// 截取url ？后的参数
export const GetRequest = (variable = "token") => {
  var query = location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return "";
};

// 获取重定向的url
export const getRedirectUrl = url => {
  const href = url;
  const hrefList = href.split("https://");
  const urls = hrefList[1].split("?");
  const Url = "https://" + urls[0];
  return Url;
};
