import BigNumber from "bignumber.js";
import { Table, WTooltip } from "@wlhy-web-components/common";
import {
  formatToDateTime,
  isFunction,
  isArray,
  isString,
  isNumber
} from "@wlhy-web-lib/shared";
import ActionBtn from "@/components/ActionBtn";
import { preview } from "@/helper/preview-helper";
import { volumeUnitRate } from "@/constants/enum/global";
import "./style.less";
import { useResizeEvent, defaultProviderKey } from "@/utils/async-observable";

Table.setPageSizeList(["10", "20", "50"]);

/**
 * 格式化时间显示：yyyy-MM-dd
 */
Table.render("p-date", {
  default({ cellValue, props }) {
    if (!cellValue) return [];

    const { pattern = "yyyy.MM.dd" } = props || {};
    return [formatToDateTime(cellValue, pattern)];
  }
});

/**
 * 格式化时间显示：yyyy-MM-dd hh:mm:ss
 */
Table.render("p-date-time", {
  default({ cellValue, props }) {
    if (!cellValue) return [];

    const { pattern = "yyyy.MM.dd hh:mm:ss" } = props || {};
    return [formatToDateTime(cellValue, pattern)];
  }
});

/**
 * 图片查看逻辑
 */
Table.render("p-preview", {
  default: ({ props, cellValue, row }) => {
    const { fields = [] } = props || {};

    const properties = (fields.length
      ? fields.map(v => row[v])
      : [cellValue]
    ).filter(v => v);
    if (!properties.length) return [];

    return [<a onClick={() => preview(...properties)}>查看</a>];
  }
});

/**
 * ResultTable 的操作列
 */
Table.render("p-operator", {
  default: ({ props, row }) => {
    const { buttons = [], dropdown = [] } = props || {};

    if (!buttons.length && !dropdown.length) return [];

    // 判断是否需要渲染的逻辑
    const predicate = item => {
      // 空数据不需要渲染
      if (!item) return false;

      // 自身包含判断逻辑时传入 iif 进行判断
      if (item.iif && isFunction(item.iif)) {
        return item.iif({ row });
      }

      // 自身不包含 iif 的直接返回 true
      return true;
    };

    const buttonOptions = buttons
      .filter(predicate)
      .map(v => ({ ...v, handler: () => v.handler(row) }));
    const dropdownOptions = dropdown
      .filter(predicate)
      .map(v => ({ ...v, handler: () => v.handler(row) }));

    return [<ActionBtn buttons={buttonOptions} dropdown={dropdownOptions} />];
  }
});

/**
 * ResultTable 的枚举转换列
 */
Table.render("p-enum", {
  default({ props, cellValue }) {
    if (!cellValue) return [];

    const { mapper = {} } = props || {};
    return [mapper[cellValue]];
  }
});

/**
 * ResultTable 的数值显示转换
 */
Table.render("p-number", {
  default({ props, cellValue }) {
    if (!cellValue) return [];

    const { decimals = 2 } = props || {};
    return [new BigNumber(cellValue).toFixed(decimals, BigNumber.ROUND_DOWN)];
  }
});

/**
 * ResultTable 的金额显示转换
 */
Table.render("p-money", {
  default({ props, cellValue }) {
    if (!cellValue) return [];

    const { decimals = 2 } = props || {};
    // 金额从分转为元
    const result = new BigNumber(cellValue)
      .dividedBy(100)
      .toFixed(decimals, BigNumber.ROUND_DOWN);
    return [result];
  },
  footer({ props, cellValue }) {
    if (!cellValue) return [];

    const { decimals = 2 } = props || {};
    // 金额从分转为元
    const result = new BigNumber(cellValue)
      .dividedBy(100)
      .toFixed(decimals, BigNumber.ROUND_DOWN);
    return [result];
  }
});

/**
 * ResultTable 的体积显示转换
 */
Table.render("p-volume", {
  default({ props, cellValue }) {
    if (!cellValue) return [];

    const { decimals = 2 } = props || {};
    // 体积从默认的立方厘米转换为立方米
    const result = new BigNumber(cellValue)
      .dividedBy(volumeUnitRate)
      .toFixed(decimals, BigNumber.ROUND_DOWN);
    return [result];
  },
  footer({ props, cellValue }) {
    if (!cellValue) return [];

    const { decimals = 2 } = props || {};
    // 体积从默认的立方厘米转换为立方米
    const result = new BigNumber(cellValue)
      .dividedBy(volumeUnitRate)
      .toFixed(decimals, BigNumber.ROUND_DOWN);
    return [result];
  }
});

/**
 * ResultTable 的重量显示转换
 */
Table.render("p-weight", {
  default({ props, cellValue }) {
    if (!cellValue) return [];

    const { decimals = 2 } = props || {};
    // 重量需要从企业设置中获取当前需要显示的单位换算比例
    const result = new BigNumber(cellValue)
      .dividedBy(1000000)
      .toFixed(decimals, BigNumber.ROUND_DOWN);
    return [result];
  },
  footer({ props, cellValue }) {
    if (!cellValue) return [];

    const { decimals = 2 } = props || {};
    // 重量需要从企业设置中获取当前需要显示的单位换算比例
    const result = new BigNumber(cellValue)
      .dividedBy(1000000)
      .toFixed(decimals, BigNumber.ROUND_DOWN);
    return [result];
  }
});

Table.setColumnInterceptor(() => [
  {
    label: "null-render", // 在渲染结果为 null 的时候，统一渲染为 -
    value(next) {
      const rendered = next();

      if (
        !rendered || // 渲染为某些对象的时候的判断
        (typeof rendered === "string" && rendered.trim() === "-") || // 渲染为字符串的判断
        (isArray(rendered) && // 渲染是数组的判断
          (!rendered.length || // 空数组需要替换
            rendered.every(
              v =>
                v === null ||
                v === undefined ||
                (typeof v === "string" && v.trim() === "")
            ))) // 数组中的元素全是 false 需要替换，但数字 0 不替换
      ) {
        return ["-"];
      }

      // 其它所有情况不替换
      return rendered;
    }
  },
  {
    label: "tooltip-render", // 字符串和数字无法全部显示的情况下，添加 Tooltip 提示
    value: (next, config) => {
      const rendered = next();

      // 返回单个字符串或者数字的处理逻辑
      if (
        isArray(rendered) &&
        rendered.length === 1 &&
        rendered[0] &&
        (isString(rendered[0]) || isNumber(rendered[0]))
      ) {
        const defaultPlacement =
          config.columnIndex < config.columnCount - 2 ? "topLeft" : "topRight";
        return [
          <WTooltip.Tooltip
            content={rendered[0]}
            placement={config.props?.tipsPlacement || defaultPlacement}
          />
        ];
      }

      // 其它所有情况不替换
      return rendered;
    }
  }
]);

Table.setFooterRenderInterceptor(current =>
  current.add((next, config) => {
    const rendered = next();

    // 返回单个字符串或者数字的处理逻辑
    if (
      isArray(rendered) &&
      rendered.length === 1 &&
      rendered[0] &&
      (isString(rendered[0]) || isNumber(rendered[0]))
    ) {
      const defaultPlacement =
        config.columnIndex < config.columnCount - 2 ? "topLeft" : "topRight";

      return [
        <WTooltip.Tooltip
          content={rendered[0]}
          placement={config.props?.tipsPlacement || defaultPlacement}
        />
      ];
    }

    return rendered;
  })
);

Table.setHook(args => {
  useResizeEvent(
    ({ height }, key) => {
      if (key !== defaultProviderKey) return;

      const { $el } = args.grid.value;
      let tableHeight = height - 48 + 15 - $el.parentNode.offsetTop;
      if (args.customAttrs["dataKey"] === "modal") {
        tableHeight -= 45;
      }
      if (args.heightRef.value !== tableHeight) {
        args.heightRef.value = tableHeight;
      }
    },
    (x, y) => x.height !== y.height
  );
});

Table.setHook(args => {
  args.customAttrs.slots = {
    empty: () => {
      return (
        <div class="no-data">
          <img
            src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/empty.svg"
            alt=""
          />
          <div class="desc">暂无数据</div>
        </div>
      );
    }
  };
});
