import { compose } from "@wlhy-web-lib/shared";
import { routerService } from "@wlhy-web-lib/spa";
import { PageLayout } from "@wlhy-web-components/common";
import { getCurrentInstance, onBeforeUnmount, reactive, ref } from "vue";

import { useInitResizeProvider } from "@/utils/async-observable";

PageLayout.setHook(() => {
  useInitResizeProvider();
});

const subscribers = { value: [] };

PageLayout.setClickChange("router", (index, config) => {
  config.tabIndex = index;

  const item = config.tabList.find((_, i) => i === index);
  if (!item) return;

  compose(query => routerService.push({ query }), ...subscribers.value).exec({
    status: item.id
  });
});

PageLayout.setDefaultClickChangeName("router");

export const useMainService = (
  tabList = [],
  tabIndex = 0,
  restConfig = { tabType: "second" }
) => {
  const self = getCurrentInstance().proxy;
  const pageLayoutConfig = reactive({ tabList, tabIndex, ...restConfig });

  if (self.$route) {
    const { status } = self.$route.query;
    if (status) {
      const index = tabList.findIndex(v => v.id.toString() === status);

      if (index >= 0) {
        pageLayoutConfig.tabIndex = index;
      }
    }
  }

  return pageLayoutConfig;
};

export const useTabTitle = (tabList = [], tabIndex = 0, queryName = "type") => {
  const self = getCurrentInstance().proxy;
  const currentTabIndexRef = ref(tabIndex);

  const refreshIndex = query => {
    const status = query[queryName];
    if (status) {
      const index = tabList.findIndex(v => v.id.toString() === status);

      if (index >= 0) {
        currentTabIndexRef.value = index;
      }
    } else {
      currentTabIndexRef.value = tabIndex;
    }
  };

  if (self.$route) {
    refreshIndex({ [queryName]: self.$route.query[queryName] });
  }

  const handleQueryChanged = (next, config) => {
    const res = next();

    refreshIndex(config);

    return res;
  };
  subscribers.value.push(handleQueryChanged);

  onBeforeUnmount(() => {
    const index = subscribers.value.findIndex(v => v === handleQueryChanged);
    if (index >= 0) {
      subscribers.value.splice(index, 1);
    }
  });

  const handleChangeTab = index => {
    if (index < 0 || index >= tabList.length) return;

    const item = tabList.find((_, i) => i === index);
    if (!item) return;

    currentTabIndexRef.value = index;
    routerService.push({
      query: { ...self.$route.query, [queryName]: item.id }
    });
  };

  return {
    tabList,
    handleChangeTab,
    currentTabIndex: currentTabIndexRef
  };
};
