import Layout from "@/layout";

const asyncRoutes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
    meta: { title: "登录页" },
    hidden: true
  },
  {
    path: "/customer",
    name: "Customer",
    component: Layout,
    redirect: "/customer/enterprise",
    hidden: true,
    meta: {
      title: "账户管理",
      icon: "iconzhanghuguanli"
    },
    children: [
      {
        path: "enterprise",
        name: "Enterprise",
        component: () => import("@/views/Account/BusinessAccount/Main.vue"),
        meta: { title: "企业账户" }
      },
      {
        path: "enterprise-detail",
        name: "EnterpriseDetail",
        component: () => import("../views/Account/ConsumerCard/Main.vue"),
        meta: { title: "消费卡", selectKeys: "enterprise" },
        hidden: true
      }
    ]
  },
  {
    path: "/financial",
    name: "Financial",
    redirect: "/financial/spending-bills",
    component: Layout,
    meta: {
      title: "财务管理",
      icon: "iconcaiwuguanli"
    },
    children: [
      {
        path: "spending-bills",
        name: "SpendingBills",
        component: () => import("@/views/Financial/SpendingBills/Main.vue"),
        meta: { title: "消费账单" }
      },
      {
        path: "recharge-record",
        name: "RechargeRecord",
        component: () => import("@/views/Financial/PrepaidRecords/Main.vue"),
        meta: { title: "充值记录" }
      }
    ]
  },
  {
    path: "/order",
    name: "Order",
    component: Layout,
    redirect: "/order/service-order",
    meta: {
      title: "订单管理",
      icon: "icondingdanguanli"
    },
    children: [
      {
        path: "service-order",
        name: "ServiceOrder",
        component: () => import("@/views/Order/ServicerOrder/Main.vue"),
        meta: { title: "服务订单" }
      }
    ]
  },
  {
    path: "/etc",
    name: "Etc",
    component: Layout,
    redirect: "/etc/make-invoice",
    meta: {
      title: "ETC服务",
      icon: "iconETCfuwu"
    },
    children: [
      {
        path: "make-invoice",
        name: "KakeInvoice",
        component: () => import("@/views/Etc/MakeInvoice/Main.vue"),
        meta: { title: "ETC开票" }
      },
      {
        path: "vehicles-record",
        name: "VehiclesRecord",
        component: () => import("@/views/Etc/VehiclesRecord/Main.vue"),
        meta: { title: "车辆备案" }
      },
      {
        path: "invoice-management",
        name: "InvoiceManagement",
        component: () => import("@/views/Etc/InvoiceManagement/Main.vue"),
        meta: { title: "开票管理" }
      }
    ]
  },
  {
    path: "/contract",
    name: "Contract",
    component: Layout,
    redirect: "/contract/service-contract",
    meta: {
      title: "合同管理",
      icon: "iconhetongguanli"
    },
    children: [
      {
        path: "service-contract",
        name: "ServiceContract",
        component: () => import("@/views/Contract/ServiceContract/Main.vue"),
        meta: { title: "服务合同" }
      },
      {
        path: "viewer-contract",
        name: "ViewerContract",
        component: () =>
          import(
            "@/views/Contract/ServiceContract/constant/contractContent.vue"
          ),
        meta: { title: "查看合同", selectKeys: "contract" },
        hidden: true
      }
    ]
  },
  {
    path: "/invoice",
    name: "Invoice",
    component: Layout,
    redirect: "/invoice/service-invoice",
    meta: {
      title: "服务发票",
      icon: "iconfuwufapiao"
    },
    children: [
      {
        path: "service-invoice",
        name: "ServiceInvoice",
        component: () => import("@/views/Invoice/ServiceInvoice/Main.vue"),
        meta: { title: "发票管理" }
      },
      {
        path: "invoice-detail",
        name: "InvoiceDetail",
        component: () =>
          import(
            "@/views/Invoice/ServiceInvoice/components/makeInvoiceRecordsDetails.vue"
          ),
        meta: { title: "开票记录详情", selectKeys: "invoice" },
        hidden: true
      },
      {
        path: "apply-invoice",
        name: "ApplyInvoice",
        component: () => import("@/views/Invoice/ApplyInvoice/Main.vue"),
        meta: { title: "申请发票" },
        hidden: true
      }
    ]
  },
  {
    path: "/oilCard",
    name: "OilCard",
    component: Layout,
    redirect: "/oilCard/account-enterprise",
    meta: {
      title: "油卡服务",
      icon: "iconyouqifuwu"
    },
    children: [
      {
        path: "account-enterprise",
        name: "AccountEnterprise",
        component: () => import("@/views/OilCard/AccountEnterprise/Main.vue"),
        meta: { title: "企业油卡账户" }
      },
      {
        path: "account-enterprise-recharge",
        name: "AccountEnterpriseRecharge",
        component: () =>
          import("@/views/OilCard/AccountEnterpriseRecharge/Main.vue"),
        meta: {
          title: "企业油卡充值记录",
          selectKeys: "account-enterprise"
        },
        hidden: true
      },
      {
        path: "account-driver",
        name: "AccountDriver",
        component: () => import("@/views/OilCard/AccountDriver//Main.vue"),
        meta: { title: "司机油卡账户" }
      },
      {
        path: "account-driver-detail",
        name: "AccountDriverDetail",
        component: () =>
          import("@/views/OilCard/AccountDriverDetail//Main.vue"),
        meta: { title: "司机油卡账户详情", selectKeys: "account-driver" },
        hidden: true
      },
      {
        path: "consumer-orders",
        name: "ConsumerOrders",
        component: () => import("@/views/OilCard/ConsumerOrders//Main.vue"),
        meta: { title: "消费订单" }
      },
      {
        path: "consumer-orders-detail",
        name: "ConsumerOrdersDetail",
        component: () =>
          import("@/views/OilCard/ConsumerOrdersDetail//Main.vue"),
        meta: { title: "订单详情" },
        hidden: true
      },
      {
        path: "transfer-record",
        name: "TransferRecord",
        component: () => import("@/views/OilCard/TransferRecord//Main.vue"),
        meta: { title: "划拨记录" }
      }
    ]
  },
  {
    path: "/land",
    name: "Land",
    component: Layout,
    redirect: "/land/lang-page",
    meta: {
      title: "增值服务",
      icon: "icondingdanguanliweixuanze"
    },
    hidden: true,
    children: [
      {
        path: "land-page",
        name: "LangPage",
        component: () => import("@/views/Account/BusinessAccount/Main.vue"),
        meta: { title: "增值服务" }
      }
    ]
  },

  {
    path: "/:catchAll(.*)",
    name: "*error",
    redirect: "/error",
    hidden: true
  },
  {
    path: "/system",
    name: "System",
    component: Layout,
    redirect: "/system/account-set",
    meta: {
      title: "设置",
      icon: "iconxitongshezhiweixuanze",
      keepAlive: true
    },
    children: [
      {
        path: "enterprise-info",
        name: "EnterpriseInfo",
        component: () => import("../views/Account/EnterpriseInfo/Main.vue"),
        meta: { title: "企业信息" }
      },
      {
        path: "account-set",
        name: "AccountSet",
        component: () => import("@/views/System/AccountSet/Main.vue"),
        meta: { title: "企业自有账号配置" }
      },
      {
        path: "account-number",
        name: "AccountNumber",
        component: () => import("@/views/System/AccountNumber/Main.vue"),
        meta: { title: "账号管理" }
      },
      {
        path: "authority",
        name: "Authority",
        component: () => import("@/views/System/Authority/Main.vue"),
        meta: { title: "角色权限管理" }
      }
    ]
  }
];
export default asyncRoutes;
