//list 转化成map表
export const listToMap = (list, keyName = "value", labelName = "label") => {
  return list.reduce((res, cur) => {
    res[cur[keyName]] = cur[labelName];
    return res;
  }, {});
};

export const customerList = [
  { value: "1", label: "企业" },
  { value: "2", label: "个人" }
];

export const customerMap = listToMap(customerList);

//车牌颜色
export const plateColorList = [
  { value: 1, label: "蓝色" },
  { value: 2, label: "黄色" },
  { value: 3, label: "黑色" },
  { value: 4, label: "白色" },
  { value: 5, label: "绿色" },
  { value: 91, label: "农黄色" },
  { value: 92, label: "农绿色" },
  { value: 93, label: "黄绿色" },
  { value: 94, label: "渐变绿" },
  { value: 9, label: "其他" }
];

export const plateColorMap = listToMap(plateColorList);

//汽车能源类型
export const energyTypeList = [
  { value: "A", label: "汽油" },
  { value: "B", label: "柴油" },
  { value: "C", label: "电" },
  { value: "D", label: "混合油" },
  { value: "E", label: "天然气" },
  { value: "F", label: "液化石油气" },
  { value: "L", label: "甲醇" },
  { value: "M", label: "乙醇" },
  { value: "N", label: "太阳能" },
  { value: "O", label: "混合动力" },
  { value: "Y", label: "无" },
  { value: "Z", label: "其他" }
];

export const energyTypeMap = listToMap(energyTypeList);

//车辆审核状态
export const auditStatusList = [
  { value: 1, label: "待审核" },
  { value: 2, label: "已审核" },
  { value: 3, label: "不通过" }
];

export const auditStatusMap = listToMap(auditStatusList);

//车辆类型，mock用，由接口请求获取
export const truckTypeList = [
  { value: "1", label: "小型货车" },
  { value: "2", label: "中型车" },
  { value: "3", label: "大货车" }
];

export const truckTypeMap = listToMap(truckTypeList);

//审核状态颜色
export const tagColorMap = {
  1: "#FC9412",
  2: "#FC9412",
  3: "#FF3F63"
};

//地址管理-地址来源
export const addressResourceMap = {
  1: "手动添加",
  2: "客户管理"
};

//银行账户类型
export const bankTypeList = [
  { value: 1, label: "银行账户" },
  { value: 2, label: "在线账户" }
];

export const bankTypeMap = listToMap(bankTypeList);

//项目状态
export const projectStatusList = [
  { value: "1", label: "启用中" },
  { value: "2", label: "已停用" }
];

export const projectStatusMap = listToMap(projectStatusList);

//开户状态
export const accountStatusList = [
  { value: -2, label: "未开户" },
  { value: -1, label: "待开户" },
  { value: 0, label: "开户中" },
  { value: 1, label: "已开户" },
  { value: 2, label: "开户失败" },
  { value: 3, label: "账户已禁用" }
];

export const accountStatusMap = listToMap(accountStatusList);
