<template>
  <div>
    <Header
      :class="routeTitle !== '增值服务' ? 'head-layout' : ''"
      :style="{
        width: collapsedValue ? 'calc(100% - 80px)' : 'calc(100% - 200px)'
      }"
      v-if="routeTitle !== '增值服务'"
    >
      <div class="head-left flex">
        <MenuUnfoldOutlined
          v-if="$store.state.app.collapsed"
          class="trigger"
          @click="toggleCollapsed"
        />
        <MenuFoldOutlined v-else class="trigger" @click="toggleCollapsed" />
        <!-- 刷新 -->
        <RedoOutlined
          class="mr-16"
          style="fong-size:16px;cursor:pointer;"
          @click="hanleRefersh"
        />
        <!-- 面包屑 -->
        <Breadcrumb :routes="$store.state.app.breadcrumbRoutes">
          <template #itemRender="{ route }">
            <span>
              {{ route.meta.title }}
            </span>
          </template>
        </Breadcrumb>
      </div>

      <div class="head-right flex" v-if="$store.state.user.userName">
        <!-- 下载中心 -->
        <div
          class="mr-16 d-flex align-center"
          style="cursor:pointer;"
          @click="handleToDownloadCenter"
        >
          <a-badge :dot="$store.getters.downloadCount" class="mr-8">
            <Icon name="iconxiazaizhongxin" height="20" width="20"></Icon>
          </a-badge>
          <p class="pa-4">下载中心</p>
        </div>
        <div class="mr-16">
          <a-select
            v-model:value="$store.state.user.companyId"
            @change="handleChengeCompany"
          >
            <a-select-option
              v-for="(item, index) in $store.state.user.companies"
              :key="index"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>

        <!-- 头像 -->
        <div class="mt-20">
          <a-dropdown
            placement="bottomCenter"
            :trigger="['click']"
            v-model:visible="visible"
          >
            <div @click.prevent>
              <Icon
                name="iconmorentouxiang"
                class="icon-loginout"
                @click="handleClick"
                height="32"
                width="32"
              ></Icon>
            </div>
            <template #overlay>
              <div class="flex-dropdown">
                <div
                  class="flex-droptop d-flex justify-space-start align-center pl-8"
                >
                  <Icon name="iconmorentouxiang" height="32" width="32"></Icon>
                  <p class="ml-8" style="color:#fff">
                    {{ $store.state.user.userName }}
                  </p>
                </div>
                <div style="color:#4D4D4D">
                  <p
                    class="py-8 px-16 option"
                    style="cursor:pointer"
                    @click="handleChangePassword"
                  >
                    修改密码
                  </p>
                  <p
                    class="py-8 px-16 option"
                    style="cursor:pointer"
                    @click="handleLoginOut"
                  >
                    退出登录
                  </p>
                </div>
              </div>
            </template>
          </a-dropdown>
        </div>
        <p class="user-name">{{ $store.state.user.userName }}</p>
      </div>
      <div v-else>
        <!--  user/loginOut user/_login -->
        <a-button
          class="mr-24"
          type="primary"
          @click="$store.dispatch('user/_login')"
          >登录</a-button
        >
      </div>

      <w-modal
        :w="288"
        ref="modal"
        :closable="false"
        @handleOk="handleLoginOut"
        okText="退出"
        cancelText="取消"
      >
        <div>
          <div class="d-flex justify-center">
            <Icon name="iconGroup282" height="16" width="16"></Icon>
            <p class="fz-16 fw-600 ml-8 lh-18">提示</p>
          </div>
          <p class="text-center mt-16">您是否确定退出当前账号</p>
        </div>
        <template
          v-slot:footer="{ okText, handleOk, handleCancel, cancelText }"
        >
          <a-button @click="handleOk">{{ okText }}</a-button>
          <a-button type="primary" @click="handleCancel">{{
            cancelText
          }}</a-button>
        </template>
      </w-modal>
    </Header>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { Layout, Breadcrumb } from "ant-design-vue";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  RedoOutlined
} from "@ant-design/icons-vue"; //
import { useStore } from "vuex";
import { useFileDownloadCountApi } from "@/apis/download";
import { changeCompany } from "@/apis/account";

export default {
  components: {
    Header: Layout.Header,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    RedoOutlined,
    Breadcrumb
  },
  setup(_, context) {
    const router = useRouter();
    const store = useStore();

    const fileDownloadCountApi = useFileDownloadCountApi();
    const companyChange = changeCompany();
    const visible = ref(false);
    const collapsedValue = ref(false);
    const openKeys = computed(() => store.state.app.openKeys);
    const routeTitle =
      store.state.app.breadcrumbRoutes[
        store.state.app.breadcrumbRoutes.length - 1
      ].meta.title;

    const modalRef = ref();

    watch(
      () => openKeys.value,
      (val, oldVal) => {
        store.commit("app/UPDATE_PRE_OPEN_KEYS", oldVal);
      },
      {
        deep: true
      }
    );

    watch(
      () => store.state.app.collapsed,
      val => {
        collapsedValue.value = val;
      }
    );

    // 轮询统计是否有未读的已下载好的数据
    const getDownloadCount = async () => {
      const res = await fileDownloadCountApi({
        read_flag: "NO",
        status: "COMPLETED"
      });
      if (res > 0) {
        store.commit("app/UPDATE_DOWNLOAD_COUNT", true);
      } else {
        store.commit("app/UPDATE_DOWNLOAD_COUNT", false);
      }
    };

    const pathName = window.location.pathname;
    if (pathName !== "/land/land-page") {
      setInterval(async () => {
        getDownloadCount();
      }, 60000);
    }

    const handleClick = () => {
      // modalRef.value.initShow();
    };

    const toggleCollapsed = () => {
      store.commit("app/TOGGLE_COLLAPSED");
    };

    // 登出
    const handleLoginOut = () => {
      store.dispatch("user/loginOut");
    };

    // 修改密码
    const handleChangePassword = () => {
      router.push({ name: "ResetPassword" });
      visible.value = false;
    };

    // 显示菜单栏
    const handleMenuClick = () => {
      visible.value = true;
    };

    // 刷新
    const hanleRefersh = () => {
      // location.reload();
      context.emit("refersh");
    };

    // 跳转到下载中心
    const handleToDownloadCenter = () => {
      router.push({ name: "DownloadCenter" });
    };

    //切换企业
    const handleChengeCompany = async value => {
      console.log(value);
      let res = await companyChange({ company_id: value });
      if (res) {
        window.location.href = window.location.origin;
      }
    };

    return {
      visible,
      openKeys,
      routeTitle,
      collapsedValue,
      toggleCollapsed,
      modal: modalRef,
      handleClick,
      hanleRefersh,
      handleLoginOut,
      handleMenuClick,
      handleChangePassword,
      handleToDownloadCenter,
      handleChengeCompany
    };
  }
};
</script>

<style lang="less" scoped>
.head-layout {
  background: #fff !important;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d4d4d4;
  font-size: 12px;
  position: fixed;
  z-index: 10;
  width: calc(~"100% - 200px");
  .trigger {
    margin-top: 8px;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .icon-loginout {
    margin-right: 6px;
    cursor: pointer;
  }
  .user-name {
    margin: 0 24px 0 8px;
  }
}
.flex-dropdown {
  width: 160px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15),
    0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: -20px;
  margin-right: 20px;
  background: #fff;
  .option {
    &:hover {
      background: #1684fb;
      color: #fff;
    }
  }
}
.flex-droptop {
  height: 56px;
  background: #1684fb;
  border-radius: 4px 4px 0px 0px;
}
</style>
