import "xe-utils";
import { isUnDef, isFunction } from "@wlhy-web-lib/shared";
import VXETable from "vxe-table";
import Components, { Searcher, PageLayout } from "@wlhy-web-components/common";
import {
  Form,
  Input,
  Button,
  Radio,
  Popconfirm,
  Drawer,
  InputNumber,
  Select,
  DatePicker,
  Statistic,
  Spin,
  Row,
  Col,
  Checkbox,
  Modal,
  Cascader,
  Carousel,
  Badge,
  Tooltip,
  Table,
  Dropdown
} from "ant-design-vue";
import PictureUploader, {
  Trigger as PictureUploaderTrigger
} from "@/components/PictureUploader";
import "@/components/ResultTable/renderers";
import PDatePicker from "@/components/DatePicker";
import PCascader from "@/components/cascader/Index";
import "@/components/PageLayout/UseMainService";
// import "@wlhy-web-components/common/lib/lib/index.less";

import Icon from "@/components/Icon";

import "../ajax";

import "vxe-table/lib/style.css";

const components = [Icon];

const install = app => {
  const componentsInstall = app => {
    if (componentsInstall.installed) return;
    components.map(component => app.component(component.name, component));
  };

  const installObj = {
    install: componentsInstall,
    ...components
  };

  app
    .use(installObj)
    .use(Components)
    .use(Form)
    .use(Button)
    .use(Spin)
    .use(Row)
    .use(Col)
    .use(Input)
    .use(Modal)
    .use(VXETable)
    .use(Popconfirm)
    .use(Drawer)
    .use(InputNumber)
    .use(Select)
    .use(Checkbox)
    .use(DatePicker)
    .use(Statistic)
    .use(components)
    .use(Cascader)
    .use(Carousel)
    .use(Badge)
    .use(Tooltip)
    .use(Table)
    .use(Dropdown)
    .use(Radio)
    .component(PictureUploader.name, PictureUploader)
    .component(PDatePicker.name, PDatePicker)
    .component(PCascader.name, PCascader)
    .component(PictureUploaderTrigger.name, PictureUploaderTrigger)
    .component(PageLayout.PageLayout.name, PageLayout.PageLayout);

  Searcher.extendComponent("dateRange", "dateRange", {
    defaultValue: {}
  });

  Searcher.extendComponent("select", "select-search", {
    getRefreshList(config) {
      let defaultValue;
      if (isFunction(this.defaultValue)) {
        defaultValue = this.defaultValue(config);
      } else {
        defaultValue = this.defaultValue;
      }

      const refreshList = defaultValue.refreshList;
      return isUnDef(config.refreshList) ? refreshList : config.refreshList;
    },

    // 获取渲染时绑定的 events
    getBindingEvents(dataSourceRef, config) {
      return {
        change(value) {
          dataSourceRef.value[config.dataKey] = value;
        }
      };
    },
    // 获取绑定时的 props
    getBindingProps(dataSourceRef, config) {
      const refresh = this.getRefreshList(config);
      const currentOptions = config.options || [];
      return {
        value: dataSourceRef.value[config.dataKey],
        options: refresh ? refresh.exec(currentOptions) : currentOptions,
        showSearch: true,
        filterOption: (input, option) => {
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
        defaultActiveFirstOption: true,
        ...config.props
      };
    }
  });

  Searcher.extendComponent("select", "select-data-search", {
    getRefreshList(config) {
      let defaultValue;
      if (isFunction(this.defaultValue)) {
        defaultValue = this.defaultValue(config);
      } else {
        defaultValue = this.defaultValue;
      }

      const refreshList = defaultValue.refreshList;
      return isUnDef(config.refreshList) ? refreshList : config.refreshList;
    },

    // 获取渲染时绑定的 events
    getBindingEvents(dataSourceRef, config) {
      return {
        change(value) {
          dataSourceRef.value[config.dataKey] = value;
        }
        /* search(value) {
          config.props.onSearch(value);
        },
        select() {
          // 选择的时候将列表重置
          config.props.onSearch();
        } */
      };
    },

    // 获取绑定时的 props
    getBindingProps(dataSourceRef, config) {
      const refresh = this.getRefreshList(config);
      const currentOptions = config.options || [];
      return {
        value: dataSourceRef.value[config.dataKey],
        options: refresh ? refresh.exec(currentOptions) : currentOptions,
        showSearch: true,
        filterOption: (input, option) => {
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
        defaultActiveFirstOption: true,
        ...config.props
      };
    }
  });
};

export default install;
