import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/company");

// 下载中心
// 下载中心-列表
export const useFileDownloadPageApi = basicApi({
  url: "/file_download/page",
  withCredentials: true
});
// 下载中心-统计
export const useFileDownloadCountApi = basicApi({
  url: "/file_download/count",
  withCredentials: true
});
// 下载中心-更新标识
export const useFileDownloadUpdateFlagApi = basicApi({
  url: "/file_download/update_read_flag",
  withCredentials: true
});
// 下载中心-删除
export const useFileDownloadDeleteApi = basicApi({
  url: "/file_download/delete",
  withCredentials: true
});
