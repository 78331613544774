<template>
  <Sider
    v-model:collapsed="$store.state.app.collapsed"
    :trigger="null"
    collapsible
  >
    <div class="logo">
      <!-- <span style="color:#3C4E64" v-if="!$store.state.app.collapsed"
        >快驰科技服务市场客户端</span
      > -->
      <img
        v-if="!$store.state.app.collapsed"
        src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/client/common/title.svg"
      />
    </div>
    <Menu
      theme="dark"
      mode="inline"
      v-model:selectedKeys="$store.state.app.selectedKeys"
      :openKeys="$store.state.app.openKeys"
      :inline-collapsed="$store.state.app.collapsed"
      @openChange="handleOpenChange"
      @click="handleRoutePush"
    >
      <template v-for="item in routes" :key="item.key">
        <!-- 有且不仅有一个子路由 -->
        <Sub-menu
          v-if="item.children && !~firstLevelMenu.indexOf(item.name)"
          :key="item.key"
        >
          <template #title>
            <span class="d-flex">
              <Icon
                :name="item.meta.icon"
                class="mt-12 mr-8 anticon"
                height="16"
                width="16"
              ></Icon>
              <span>{{ item.meta.title }}</span>
            </span>
          </template>
          <template v-for="child in item.children" :key="child.key">
            <Menu-item v-if="!child.hidden" :key="child.key">
              {{ child.meta.title }}
            </Menu-item>
          </template>
        </Sub-menu>
        <!-- 只有一个子路由 -->
        <Menu-item v-else :key="item.key">
          <Icon
            :name="item.meta.icon"
            class="mt-12 mr-8 anticon"
            height="16"
            width="16"
          ></Icon>
          <span style="color:#d4d4d4">{{ item.meta.title }}</span>
        </Menu-item>
      </template>
    </Menu>
    <p class="version">版本号：1.3.0.0</p>
  </Sider>
</template>

<script>
import { ref, computed } from "vue";
import { Layout, Menu } from "ant-design-vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    Sider: Layout.Sider,
    Menu,
    "Menu-item": Menu.Item,
    "Sub-menu": Menu.SubMenu
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    // 只有一级菜单，没有二级菜单
    const firstLevelMenu = ["Home", "DataCheck"];

    // 一级菜单栏
    const rootSubmenuKeys = ref([]);

    // 菜单栏路由列表
    const routes = computed(() => {
      let filterArray = [];
      store.getters.routes.forEach(item => {
        if (!item.hidden) {
          if (item.children && item.children.length > 0) {
            item.key = item.path;
            rootSubmenuKeys.value.push(item.key);
            item.children.map(child => {
              child.key = `${item.path}/${child.path}`;
              return child;
            });
          } else {
            item.key = item.path;
          }
          filterArray.push(item);
        }
      });
      return filterArray;
    });

    // 一级菜单状态改变
    const handleOpenChange = openKeys => {
      const latestOpenKey = openKeys.find(
        key => store.state.app.openKeys.indexOf(key) === -1
      );
      if (rootSubmenuKeys.value.indexOf(latestOpenKey) === -1) {
        store.commit("app/UPDATE_OPEN_KEYS", openKeys);
      } else {
        store.commit(
          "app/UPDATE_OPEN_KEYS",
          latestOpenKey ? [latestOpenKey] : []
        );
      }
    };

    // 路由跳转
    const handleRoutePush = item => {
      router.push(item.key);
    };
    console.log(routes);
    return {
      routes,
      firstLevelMenu,
      handleOpenChange,
      handleRoutePush
    };
  }
};
</script>
<style lang="less" scoped>
.version {
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: 32px;
  width: 100%;
}

@media screen and (max-height: 822px) {
  .version {
    position: initial;
    margin-top: 120px;
    margin-bottom: 30px;
  }
}
</style>

<style lang="less">
.ant-layout-sider-children {
  overflow: auto !important;
  height: 100vh !important;
}
.ant-menu-root {
  min-height: 476px !important;
  background: #1b1a1a !important;
}
</style>
