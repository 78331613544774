import { buildApi2 } from "@/utils/ajax";
const basicApi = buildApi2("/portal/company");

export const getUserInfoApi = basicApi({
  url: `/user_info`,
  withCredentials: true
});

export const useSendMessageApi = basicApi({
  url: `/user/sms/send`,
  withCredentials: true
});

export const useResetPasswordApi = basicApi({
  url: `/user/reset_pwd`,
  withCredentials: true
});
