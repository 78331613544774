import { buildApi, buildApi2 } from "@/utils/ajax";
import { GetRequest } from "@/utils/requestUrl";
const url = GetRequest();

const basicApi = buildApi("/portal/company");
const basicApi2 = buildApi2("/portal/company");

// 落地页
// 账户消费信息
export const useAccountAmountDetailApi = basicApi({
  url: "/account/amount_detail",
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});

// 增值服务信息
export const useAccountServiceInfoApi = basicApi({
  url: "/account/service_info",
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});

// 账户列表
export const useAccountQueryApi = basicApi({
  url: "/account/query",
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});

export const getLandUserInfoApi = basicApi2({
  url: `/user_info`,
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});

//订阅服务列表

export const useCompanySubscriptionListApi = basicApi({
  url: `/company_subscription/list`,
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});

// 开关订阅服务
export const useCompanySubscriptionUpdateStatusApi = basicApi({
  url: `/company_subscription/update_status`,
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});

//创建=》企业通用账户余额告警配置
export const useAlarmCreateConfigApi = basicApi({
  url: `/alarm/create_config`,
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});

//查询=》企业通用账户余额告警配置
export const useAlarmQueryConfigApi = basicApi({
  url: `/alarm/query_config`,
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});

//修改=》企业通用账户余额告警配置
export const useAlarmUpdateConfigApi = basicApi({
  url: `/alarm/update_config`,
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});

// 充值
export const useAccountRechargeStartApi = basicApi({
  url: "/account/recharge/start",
  params: url && url ? { token: GetRequest() } : "",
  //账户管理/企业信息/查询企业信息
  withCredentials: true
});

// 各服务月消费统计
export const useAccountByServiceApi = basicApi({
  url: "/account/count_consume_by_service",
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});
// 月消费统计
export const useAccountByMonthApi = basicApi({
  url: "/account/count_consume_by_month",
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});

// 月消费统计
export const useRechargeListApi = basicApi({
  url: "/account/card/binding_page",
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});
