<template>
  <div id="app">
    <div
      ref="pop-container"
      style="position: fixed; top: 0; left: 0; z-index: 1000; width: 0"
    />
    <TooltipContainer>
      <ConfigProvider :locale="locale" :getPopupContainer="getPopupContainer">
        <!-- <transition name="fade-slide" mode="out-in" appear>
          <router-view />
        </transition> -->
        <router-view v-slot="{ Component }">
          <transition name="fade-slide" mode="out-in" appear>
            <component :is="Component" />
          </transition>
        </router-view>
      </ConfigProvider>
    </TooltipContainer>
    <modal-center
      v-model:visible="$store.state.app.infoMissVisible"
      :width="404"
      :closable="false"
      cancelText="取消"
      okText="前往"
      @cancel="infoMissingCancel"
      @ok="infoMissingOk"
      title="提示"
      content="请先前往【企业信息】页面补充法人姓名、法人身份证号，补充后即可使用电子签章"
    ></modal-center>
  </div>
</template>

<script>
import ModalCenter from "@components/GlobalModal/Main.vue";
import { ConfigProvider } from "ant-design-vue";
import store from "@/store";
import { useRouter } from "vue-router";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { WTooltip } from "@wlhy-web-components/common";
import { useInitResizeProvider } from "@/utils/async-observable";

export default {
  components: {
    ConfigProvider,
    TooltipContainer: WTooltip.TooltipContainer,
    ModalCenter
  },
  beforeMount() {
    this.locale = {
      ...zhCN,
      Pagination: {
        ...zhCN.Pagination,
        items_per_page: "条/页",
        jump_to: "跳转至：",
        page: "页"
      }
    };
    useInitResizeProvider();
  },
  methods: {
    getPopupContainer() {
      return this.$refs["pop-container"] || document.body;
    }
  },
  setup() {
    const router = useRouter();
    //法人信息缺失
    const infoMissingCancel = () => {
      store.commit("app/UPDATE_INFO_MISS_VISIBLE", false);
    };
    const infoMissingOk = () => {
      router.push("/customer/enterprise-info");
      infoMissingCancel();
    };
    return {
      infoMissingCancel,
      infoMissingOk
    };
  }
};
</script>

<style lang="less">
#app {
  font-family: PingFang SC, PingFang SC-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
