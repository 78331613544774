<template>
  <a-modal v-bind="$attrs">
    <div class="text-center">
      <div class="d-flex align-center justify-center">
        <Icon name="icontishileiicon" width="16" height="16"></Icon>
        <p class="ml-8 fz-16 fw-500 mt-2" style="color:#3C4E64">{{ title }}</p>
      </div>
      <p class="mt-10 mb-16">{{ content }}</p>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    content: String,
    title: String
  }
});
</script>
