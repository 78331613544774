import { resetRouter } from "@/router";
// import { message } from "ant-design-vue";
import { getUserInfoApi } from "@/apis/user";
import { getLandUserInfoApi } from "@/apis/land";
import { GetRequest, getRedirectUrl } from "@/utils/requestUrl";
import useDebounce from "@/hooks/useDebounce";

const path =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_PATH
    : process.env.VUE_APP_BASEURL;

export const state = {
  token: localStorage.getItem("token") ? localStorage.getItem("token") : "", // 认证凭证'
  userName: "",
  roles: [],
  introduce: "",
  companyId: "",
  companyName: "",
  taxNumber: "",
  companies: []
};

const mutations = {
  SET_TOKEN(state, val) {
    state.token = val;
    localStorage.setItem("token", val);
  },
  DEL_TOKEN(state) {
    state.token = "";
    state.userName = "";
    state.userInfo = "";
    state.roles = "";
    state.introduce = "";
    state.companyId = "";
    state.companies = [];
    localStorage.removeItem("token");
  },
  SET_ROLES(state, payload) {
    state.roles = payload;
  },
  SET_NAME(state, payload) {
    state.userName = payload;
  },
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },
  SET_INTRODUCE(state, payload) {
    state.introduce = payload;
  },
  SET_COMPANYID(state, payload) {
    state.companyId = payload;
  },
  SET_COMPANYNAME(state, payload) {
    state.companyName = payload;
  },
  SET_TAXNUMBER(state, payload) {
    state.taxNumber = payload;
  },
  SET_COMPANIES(state, payload) {
    state.companies = payload;
  }
};
const actions = {
  // user login
  // _login() {
  //   resetRouter();
  //   message.error("登录已失效，请重新登录！");
  //   let token = GetRequest();
  //   // 从增值服务平台跳转过来带有token
  //   if (token.length !== 0) {
  //     window.location.href =
  //       path +
  //       `/portal/company/login?token=${token}` +
  //       "&redirect_url=" +
  //       encodeURIComponent(window.location.href);
  //   } else {
  //     window.location.href =
  //       path +
  //       "/portal/company/login?redirect_url=" +
  //       encodeURIComponent(window.location.href);
  //   }
  // },
  // 新增一个防抖函数
  _login: useDebounce(() => {
    //获取网页的地址
    const pathname = window.location.pathname;
    resetRouter();
    // message.error("登录已失效，请重新登录！");
    let token = GetRequest();
    // 从增值服务平台跳转过来带有token
    if (pathname === "/land/land-page") return;
    if (token.length !== 0) {
      let urls = getRedirectUrl(window.location.href);
      window.location.href =
        path +
        `/portal/company/login?token=${token}` +
        "&redirect_url=" +
        encodeURIComponent(urls);
    } else {
      window.location.href =
        path +
        "/portal/company/login?redirect_url=" +
        encodeURIComponent(window.location.href);
    }
  }, 500),
  loginOut() {
    resetRouter();
    window.location.href =
      path +
      "/portal/company/logout?redirect_url=" +
      encodeURIComponent(window.location.origin);
  },
  async _getUserInfo({ commit }) {
    try {
      let token = GetRequest();
      const getInfo = token ? getLandUserInfoApi : getUserInfoApi;
      return await getInfo().then(res => {
        const roles = [...res.menus, "*404"];
        if (res.user.username) {
          commit("SET_NAME", res.user.name);
          commit("SET_USER_INFO", res.user);
          commit("SET_COMPANYID", res.company.id);
          commit("SET_COMPANYNAME", res.company.name);
          commit("SET_TAXNUMBER", res.company.tax_number);
          commit("SET_ROLES", roles);
          commit("SET_COMPANIES", res.companies);

          localStorage.setItem("roles", JSON.stringify(roles));
        }
        return roles;
      });
    } catch (e) {
      console.log(e);
    }
  }
  /* _getInfo({ commit }) {
    return new Promise(resolve => {
      //   getInfo()
      //     .then(res => {
      //       if (res.code === 0) {
      //         const { name, roles, introduce } = res.data;
      const roles = ["*404"];
      commit("SET_ROLES", roles);
      localStorage.setItem("roles", JSON.stringify(roles));
      //         commit("SET_NAME", name);
      //         commit("SET_INTRODUCE", introduce);
      //       } else {
      //         Message.error(res.msg);
      //       }
      //       resolve(res.data);
      //     })
      //     .catch(error => {
      //       reject(error);
      //     });
      resolve({
        roles: roles
      });
    });
  } */
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
