import {
  provide,
  inject,
  onMounted,
  onBeforeUnmount,
  onUpdated,
  onActivated,
  onDeactivated
} from "vue";

export class AsyncObservable {
  constructor() {
    this.$list = [];
    this.$previous = null;

    this.on = this.on.bind(this);
    this.off = this.off.bind(this);
    this.emit = this.emit.bind(this);
  }

  on(func) {
    this.$list.push(func);

    if (this.$previous) {
      const promise = new Promise(r => {
        r(this.$previous);
      });
      promise.then(func);
    }

    return () => this.off(func);
  }

  off(func) {
    if (!func) {
      this.$list = [];
    } else {
      const index = this.$list.findIndex(v => v === func);
      if (index >= 0) {
        this.$list = [
          ...this.$list.slice(0, index),
          ...this.$list.slice(index + 1)
        ];
      }
    }
  }

  emit(val) {
    const promise = new Promise(r => {
      r(val);
      this.$previous = val;
    });

    this.$list.forEach(func => promise.then(func));
  }
}

const resizeKey = "window_resize_register_key";

export const defaultProviderKey = "main";

export const useInitResizeProvider = (key = defaultProviderKey) => {
  const list = [];
  const observable = new AsyncObservable();

  const handleResize = () => {
    observable.emit({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  const addListener = func => {
    const unregister = observable.on(func);
    list.push(unregister);

    return () => {
      unregister();

      const index = list.findIndex(v => v === unregister);
      if (index >= 0) {
        list.splice(index, 1);
      }
    };
  };

  onMounted(() => {
    if (!window) return;

    window.addEventListener("resize", handleResize);
    handleResize();
  });

  onBeforeUnmount(() => {
    if (!window) return;

    list.forEach(unregister => unregister());
    window.removeEventListener("resize", handleResize);
  });

  provide(resizeKey, { addListener, key });
};

export const useResizeEvent = (func, compare) => {
  const { addListener, key } = inject(resizeKey) || {};
  if (!addListener) {
    throw new Error(
      "useResizeEvent: 必须先初始化（ useInitResizeProvider ）才能使用"
    );
  }

  let previous = {};
  let unregister = () => {};
  let isProcessing = false;
  let nextRenderNeedEndProcessing = false;

  const handleRegister = () => {
    unregister = addListener(arg => {
      if (isProcessing) return;

      if (compare && !compare(previous, arg)) return;
      previous = arg;

      isProcessing = true;
      const promise = new Promise(r => r(arg));
      promise.then(args => {
        try {
          func(args, key);
        } finally {
          nextRenderNeedEndProcessing = true;
        }
      });
    });
  };

  onMounted(handleRegister);
  onActivated(handleRegister);

  onDeactivated(unregister);
  onBeforeUnmount(unregister);

  onUpdated(() => {
    if (nextRenderNeedEndProcessing) {
      isProcessing = false;
    }
  });
};
