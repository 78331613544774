<template>
  <div>
    <a
      v-for="btn in buttons"
      class="btn"
      :class="{ 'icon-btn': !btn.label }"
      :key="btn.key"
      href="javascript:;"
      :disabled="!!btn.disabled"
      @click.prevent="handleBtnClick(btn)"
    >
      <!-- 区别图标类和文字类按钮，根据buttons的item的label是否为truthy来区别 -->
      <span v-if="!!btn.label">{{ btn.label }}</span>
      <Icon :type="btn.icon" v-else-if="!!btn.icon"></Icon>
    </a>

    <Dropdown :trigger="trigger" v-if="dropdown.length">
      <a class="icon-btn btn" href="javascript:;"
        ><Icon type="align-left"></Icon
      ></a>
      <Menu @click="handleDropClick">
        <MenuItem :key="item.key" v-for="item in dropdown">
          {{ item.label || "按钮" }}
        </MenuItem>
      </Menu>
    </Dropdown>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { Icon, Menu, Dropdown } from "ant-design-vue";

//组件使用说明
// props = {
//   //buttons为主要按钮，Array,默认为空
//   //label代表button文字,icon代表button图标类型,优先级label>icon,handler是当前项被点击的处理函数，非必传,
//   //必传字段：key, icon或label需传一个
//   buttons: [{ key: 'edit', icon: 'edit', label: '编辑', disabled: false, handler: () => {} }],
//   //下拉按钮，key必传
//   dropdown:[{key:'delete',label:'删除',handler:()=>{}}]
//   //trigger,Array,同Dropdown组件,默认为['click']
//   trigger:['click','hover','focus']
// };
//如果传入handler函数，点击按钮会调用handler()
//没传handler可一监听key对应事件比如@edit（jsx 的 onEdit=()={}）
//也可监听aciton事件，@action=(key)=>{},触发按钮类型会在$event传

export default defineComponent({
  components: {
    Icon,
    Menu,
    MenuItem: Menu.Item,
    Dropdown
  },
  props: {
    buttons: {
      type: Array,
      default: () => []
    },
    dropdown: {
      type: Array,
      default: () => []
    },
    trigger: {
      type: Array,
      default: () => ["click"]
    }
  },
  setup(props, ctx) {
    const handleBtnClick = ({ key, handler }) => {
      ctx.emit("action", key);
      ctx.emit(key);
      typeof handler === "function" && handler();
    };
    const handleDropClick = ({ key }) => {
      const dropdown = props.dropdown || [];
      let currentItem = dropdown.find(it => it.key == key);
      currentItem &&
        typeof currentItem.handler === "function" &&
        currentItem.handler();
      ctx.emit("action", key);
      ctx.emit(key);
    };
    return { handleBtnClick, handleDropClick };
  }
});
</script>
<style lang="less" scoped>
@app-primary-color: #0076ff;
.btn {
  margin-left: 16px;
  &[disabled] {
    cursor: not-allowed;
  }
  &:first-of-type {
    margin-left: 0;
  }
  &.icon-btn {
    &:not([disabled]) {
      cursor: pointer;
      color: inherit;
    }
    &:hover {
      color: @app-primary-color;
    }
  }
}
</style>
