import Layout from "@/layout";

/**
 * 路由相关属性说明
 * hidden: 当设置hidden为true时，意思不在sideBars侧边栏中显示
 * mete{
 * title: xxx,  设置sideBars侧边栏名称
 * icon: xxx,  设置ideBars侧边栏图标
 * selectKeys: xxx, 左边
 * }
 */

/*通用routers*/
const currencyRoutes = [
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/ErrorPage/404.vue"),
    hidden: true,
    meta: { title: "404" }
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/ErrorPage/error.vue"),
    hidden: true,
    meta: { title: "error" }
  },
  {
    path: "/",
    name: "Home",
    component: Layout,
    redirect: "/home",
    meta: { title: "首页", icon: "iconGroup830" },
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/Account/BusinessAccount/Main.vue"),
        meta: { title: "增值服务" }
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        component: () => import("@/views/Common/ResetPassword/Main.vue"),
        meta: { title: "修改密码" }
      },
      {
        path: "download-center",
        name: "DownloadCenter",
        component: () => import("@/views/Common/DownloadCenter/Main.vue"),
        meta: { title: "下载中心" },
        hidden: true
      },
      {
        path: "service-management",
        name: "ServiceManagement",
        component: () => import("@/views/Account/ServiceManagement/Main.vue"),
        meta: { title: "服务管理", selectKeys: "/" },
        hidden: true
      }
    ]
  },
  {
    path: "/data",
    name: "DataCheck",
    component: Layout,
    redirect: "/data/dataCheck",
    meta: { title: "数据自检", icon: "iconshujuzijian" },
    children: [
      {
        path: "dataCheck",
        name: "DataCheck",
        component: () => import("@/views/DataCheck/Main.vue"),
        meta: { title: "数据自检" }
      }
    ]
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register/Index/Main.vue"),
    meta: { title: "注册" },
    hidden: true
  },
  {
    path: "/fill-info",
    name: "FillInfo",
    component: () => import("@/views/Register/FillInfo/Main.vue"),
    meta: { title: "注册" },
    hidden: true
  }
];
export default currencyRoutes;
