import { buildApi } from "@/utils/ajax";
import { GetRequest } from "@/utils/requestUrl";
const url = GetRequest();
const basicApi = buildApi("/portal/company");
const basicApi2 = buildApi("/portal/common");

// 切换企业
export const changeCompany = basicApi({
  url: `/switch_company`,
  withCredentials: true
});

// 账户管理
// 账户列表
export const useAccountQueryApi = basicApi({
  url: "/account/query",
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});

// 充值汇总
export const useAccountAmountInfoApi = basicApi({
  url: "/account/amount_info",
  withCredentials: true
});

// 月消费统计
export const useAccountByMonthApi = basicApi({
  url: "/account/count_consume_by_month",
  withCredentials: true
});

// 各服务月消费统计
export const useAccountByServiceApi = basicApi({
  url: "/account/count_consume_by_service",
  withCredentials: true
});

// 卡消费账单
export const useAccountDailyBillQueryApi = basicApi({
  url: "/account/daily_bill/query",
  withCredentials: true
});

// 充值
export const useAccountRechargeStartApi = basicApi({
  url: "/account/recharge/start"
  //账户管理/企业信息/查询企业信息
});
//修改企业详情
export const useUpdateCompanyDetailApi = basicApi({
  url: "/update_company_detail",
  withCredentials: true
});
//查询
export const useQueryCompanyDetailApi = basicApi({
  url: "/query_company_detail",
  withCredentials: true
});
//字典下拉列表
export const useEnumDictListApi = basicApi2({
  url: "/enum/dict_list ",
  withCredentials: true
});
