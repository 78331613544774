<template>
  <a-cascader
    :options="regions"
    :allowClear="false"
    :value="currentValue[0] ? currentValue : []"
    @blur="$emit('blur')"
    :fieldNames="fieldNames"
    :placeholder="placeholder"
    @change="handleRegionChanged"
    :disabled="disabled"
    :size="size"
  />
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import Options from "./cities.js";

export default defineComponent({
  name: "p-cascader",
  props: {
    value: Object,
    placeholder: {
      type: String,
      default: "请选择"
    },
    mapper: {
      type: Array,
      default: () => [
        { id: "procince", name: "province" },
        { id: "city", name: "city" },
        { id: "area", name: "area" }
      ]
    },
    fieldNames: {
      type: Object,
      default: () => ({ label: "label", value: "value", children: "children" })
    },
    disabled: {
      type: Boolean,
      deafult: false
    },
    size: {
      type: String,
      default: "default"
    }
  },
  setup(props, context) {
    const currentValueRef = ref([]);
    const regions = Options;
    watch(
      () => props.value.city,
      newV => {
        console.log(newV);
        currentValueRef.value = [
          props.value.province,
          props.value.city,
          props.value.area
        ];
      },
      { immediate: true }
    );

    const handleRegionChanged = v => {
      let list = regions;
      const ids = v.length ? v : [null, null, null];
      const { value, children } = props.fieldNames;
      console.log(v, props);
      ids.forEach(currentId => {
        const item = list.find(v => v[value] === currentId);
        currentValueRef.value = v;
        list = item ? item[children] || [] : [];
      });
      context.emit("change", currentValueRef);
    };

    return {
      regions,
      handleRegionChanged,
      currentValue: currentValueRef
    };
  }
});
</script>
