<template>
  <div v-if="ifRefersh">
    <Layout id="components-layout-demo-custom-trigger">
      <!-- 侧边栏 -->
      <SideBar v-if="$route.path !== '/land/land-page'" />
      <Layout>
        <!-- 头部 -->
        <Header @refersh="handleRefersh" />
        <!-- 页面内容 -->
        <PageMain />
      </Layout>
    </Layout>
  </div>
</template>

<script>
import { ref } from "vue";
import { Layout } from "ant-design-vue";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import PageMain from "./components/PageMain";

export default {
  name: "BasicLayout",
  components: {
    Layout,
    Header,
    SideBar,
    PageMain
  },

  setup() {
    const ifRefersh = ref(true);
    // const pathName = window.location.pathname; // 路由是落地页时  不显示左侧导航
    const handleRefersh = () => {
      ifRefersh.value = false;
      setTimeout(() => {
        ifRefersh.value = true;
      }, 1);
    };
    return {
      // pathName,
      ifRefersh,
      handleRefersh
    };
  }
};
</script>

<style lang="less">
@import "./styles/BasicLayout.less";
</style>
<style>
#components-layout-demo-custom-trigger {
  height: 100%;
}
#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  color: #fff;
  margin: 31px 0px;
  text-align: center;
  font-weight: 900;
  font-family: PangMenZhengDao, PangMenZhengDao-Regular;
}
</style>
