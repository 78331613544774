import { listToMap } from "@/constants/enum/basic";

// 全局重量单位枚举
export const weightUnitList = [
  { value: 1, label: "吨" },
  { value: 2, label: "千克" }
];
export const weightUnitMap = listToMap(weightUnitList);

// 体积
export const volumeUnitType = {
  METER: 10,
  DM: 20,
  CM: 30
};

export const volumeUnitTypeList = [
  { value: volumeUnitType.METER, label: "立方米" },
  { value: volumeUnitType.DM, label: "立方分米" },
  { value: volumeUnitType.CM, label: "立方厘米" }
];

export const volumeUnitMap = listToMap(volumeUnitTypeList);

// 体积比率
export const volumeUnitRate = 1000;

export const pageSizeNum = 20;
